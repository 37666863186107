<template>					
	<v-container style="text-align:center">
		<v-row>
			<v-col>
				<v-img src="/images/order-confirm.png" width="500" :style="$vuetify.breakpoint.mdAndUp ? 'margin-top: 30%; margin-left: 25%;' : ''"></v-img>
			</v-col>
			<v-col :style="$vuetify.breakpoint.mdAndUp ? 'margin-top: 18%; margin-left: 10%; margin-right: 10%' : ''">
				<p class="font-weight-bold" style="font-size: 2em">You have successfully ordered your customized seat</p>
				<p style="font-size: 1.3em">You will receive an update from us in 3-5 working days. Thank you for ordering from DK Schweizer.</p>
			</v-col>
		</v-row>
		<v-row class="justify-lg-end justify-center">
			<v-col cols="12" lg="3" class="mt-10 mr-md-15">
				<v-btn
					block
					depressed
					color="primary"
					class="text-capitalize secondary--text radius-10 mb-3"
					@click="$router.push('/order-histories')"
				>
					Return to Homepage
				</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { resourceMixin } from "@/mixins/ResourceMixin"

export default {
	components:{

	},
	mixins: [
		resourceMixin,
	],
	data(){
		return {

		}
	},
	created(){
	},
	methods:{

	}
}
</script>
